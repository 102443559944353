<template>
    <div class="mb-0 mt-4">
        <h4 class="font-weight-bolder">Custom Trends Insert</h4>
        <b-card no-body class="mb-0">
            <b-tabs pills align="center" class="mt-4" nav-class="nav-pill-danger">
                <b-tab title="Artists" active>
                    <div class="row justify-content-center my-2">
                        <v-select class="col-md-9" v-model="form.id" :loading="isLoading" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="artistOption" label="name" :clearable="false" input-id="artist" @search="filterArtist" placeholder="Select Artist">
                            <template #option="{ name }">
                                <span> {{ name }}</span>
                            </template>
                        </v-select>
                    </div>
                    <div class="row justify-content-end">
                        <b-button @click="addToListHander('artist')" variant="relief-warning" class="mt-4 btn-icon"> Add Row <feather-icon icon="PlusCircleIcon" /> </b-button>
                        <div class="col-md-1"></div>
                    </div>
                    <div class="row">
                        <div v-for="(item, index) in artistRankList" :key="item.index" class="col-md-10 text-center">
                            <label class="row mx-1">{{ index + 1 }}</label>
                            <div class="row justify-content-center my-2">
                                <div class="col-md-4">
                                    <label>From Date</label>
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" :initial-date="date" class="mx-1" v-model="item.from_date" />
                                </div>
                                <div class="col-md-4">
                                    <label>To Date</label>
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" :initial-date="date" class="mx-1" v-model="item.to_date" />
                                </div>
                                <div class="col-md-4">
                                    <label>Rank</label>
                                    <b-form-input class="mx-1" type="text" placeholder="Rank" v-model="item.rank" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 mt-3">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-2 ml-2 btn-icon" @click="onSubmit('artist')"> Submit </b-button>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Musics">
                    <div class="row justify-content-center my-2">
                        <v-select class="col-md-9" v-model="form.id" :loading="isLoading" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="musicOption" label="name" :clearable="false" input-id="artist" @search="filterMusic" placeholder="Select Music">
                            <template #option="{ name, artist }">
                                <span> {{ name }}</span>
                                -
                                <span v-if="artist.name" class="text-warning"> {{ artist.name }} </span>
                            </template>
                        </v-select>
                    </div>
                    <div class="row justify-content-end">
                        <b-button @click="addToListHander('music')" variant="relief-warning" class="mt-4 btn-icon"> Add Row <feather-icon icon="PlusCircleIcon" /> </b-button>
                        <div class="col-md-1"></div>
                    </div>
                    <div class="row my-2">
                        <div v-for="(item, index) in musicRankList" :key="item.index" class="col-md-10 text-center">
                            <label class="row mx-1">{{ index + 1 }}</label>
                            <div class="row justify-content-center">
                                <div class="col-md-4">
                                    <label>From Date</label>
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" :initial-date="date" class="mx-1" v-model="item.from_date" />
                                </div>
                                <div class="col-md-4">
                                    <label>To Date</label>
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" :initial-date="date" class="mx-1" v-model="item.to_date" />
                                </div>
                                <div class="col-md-4">
                                    <label>Rank</label>
                                    <b-form-input class="mx-1" type="text" placeholder="Rank" v-model="item.rank" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 mt-2">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-2 ml-2 btn-icon" @click="onSubmit('music')"> Submit </b-button>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Videos">
                    <div class="row justify-content-center my-2">
                        <v-select class="col-md-9" v-model="form.id" :loading="isLoading" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.id" :options="videoOption" label="name" :clearable="false" input-id="video" @search="filterVideo" placeholder="Select Video">
                            <template #option="{ name, artist }">
                                <span> {{ name }}</span>
                                -
                                <span class="text-warning"> {{ artist.name }} </span>
                            </template>
                        </v-select>
                    </div>
                    <div class="row justify-content-end">
                        <b-button @click="addToListHander('video')" variant="relief-warning" class="mt-4 btn-icon"> Add Row <feather-icon icon="PlusCircleIcon" /> </b-button>
                        <div class="col-md-1"></div>
                    </div>
                    <div class="row">
                        <div v-for="(item, index) in videoRankList" :key="item.index" class="col-md-10 text-center">
                            <label class="row mx-1">{{ index + 1 }}</label>
                            <div class="row justify-content-center my-2">
                                <div class="col-md-4">
                                    <label>From Date</label>
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" :initial-date="date" class="mx-1" v-model="item.from_date" />
                                </div>
                                <div class="col-md-4">
                                    <label>To Date</label>
                                    <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a date" local="en" :initial-date="date" class="mx-1" v-model="item.to_date" />
                                </div>
                                <div class="col-md-4">
                                    <label>Rank</label>
                                    <b-form-input class="mx-1" type="text" placeholder="Rank" v-model="item.rank" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 mt-3">
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mt-2 ml-2 btn-icon" @click="onSubmit('video')"> Submit </b-button>
                        </div>
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";
import { BFormSpinbutton, BInputGroup, BFormDatepicker, BFormCheckbox, BFormSelect, BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButtonGroup, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormValidFeedback } from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { computed, watch, ref, onMounted } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useTrendingList from "./useTrendingList";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ArtistRepository from "@/abstraction/repository/artistRepository";
import MusicRepository from "@/abstraction/repository/musicRepository";
import VideoRepository from "@/abstraction/repository/videoRepository";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        AppCollapse,
        AppCollapseItem,
        BTab,
        Cleave,
        BFormInput,
        BInputGroup,
        BFormCheckbox,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BFormSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormSpinbutton,
        ToastificationContent,
        apexchart: VueApexCharts,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        flatPickr,
        BFormDatepicker,
        BButtonGroup,
    },
    directives: {
        Ripple,
    },
    setup(props, context) {
        //data
        const { storeManualTrends, date } = useTrendingList();
        const isLoading = ref(false);
        const form = ref({
            id: undefined,
        });
        const artistRepository = new ArtistRepository();
        const musicRepository = new MusicRepository();
        const videoRepository = new VideoRepository();

        const coeffientVal = ref(1);
        const validation = ref(true);
        const artistOption = ref(undefined);
        const musicOption = ref(["A", "B", "C"]);
        const videoOption = ref(["A", "B", "C"]);
        const selectedArtist = ref("");
        const selectedMusic = ref("");
        const selectedVideo = ref("");
        const artistRankIndex = ref(0);
        const musicRankIndex = ref(0);
        const videotRankIndex = ref(0);
        const options = ref({
            number: {
                numeral: true,
                numeralThousandsGroupStyle: "thousand",
            },
        });
        const artistRankList = ref([
            {
                from_date: undefined,
                to_date: undefined,
                rank: undefined,
                name: undefined,
            },
        ]);
        const musicRankList = ref([
            {
                from_date: undefined,
                to_date: undefined,
                rank: undefined,
            },
        ]);
        const videoRankList = ref([
            {
                from_date: undefined,
                to_date: undefined,
                rank: undefined,
            },
        ]);
        const addToListHander = (val, name) => {
            if (val === "artist") {
                artistRankList.value.push({
                    from_date: undefined,
                    to_date: undefined,
                    rank: undefined,
                    name: undefined,
                });
                artistRankIndex.value++;
            } else if (val === "music") {
                musicRankList.value.push({
                    from_date: undefined,
                    to_date: undefined,
                    rank: undefined,
                });
                musicRankIndex.value++;
            } else if (val === "video") {
                videoRankList.value.push({
                    from_date: undefined,
                    to_date: undefined,
                    rank: undefined,
                });
                videotRankIndex.value++;
            }
        };
        const onSubmit = async (type) => {
            console.log(type);
            if (type === "artist") {
                let data = artistRankList.value[artistRankIndex.value];
                data.type = type;
                data.id = form.value.id;
                console.log(data);
                storeManualTrends(data).then((res) => {
                    if (res === false) {
                        context.root.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "BellIcon",
                                text: "Couldn't submit !",
                                variant: "danger",
                            },
                        });
                    } else {
                        context.root.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Updated",
                                icon: "BellIcon",
                                text: "Added !",
                                variant: "success",
                            },
                        });
                    }
                });
            } else if (type === "music") {
                let data = musicRankList.value[musicRankIndex.value];
                data.type = type;
                data.id = form.value.id;
                console.log(data);
                storeManualTrends(data).then((res) => {
                    if (res === false) {
                        context.root.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "BellIcon",
                                text: "Couldn't submit !",
                                variant: "danger",
                            },
                        });
                    } else {
                        context.root.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Updated",
                                icon: "BellIcon",
                                text: "Added !",
                                variant: "success",
                            },
                        });
                    }
                });
            } else if (type === "video") {
                let data = videoRankList.value[videotRankIndex.value];
                data.type = type;
                data.id = form.value.id;
                console.log(data);
                storeManualTrends(data).then((res) => {
                    if (res === false) {
                        context.root.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Error",
                                icon: "BellIcon",
                                text: "Couldn't submit !",
                                variant: "danger",
                            },
                        });
                    } else {
                        context.root.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Updated",
                                icon: "BellIcon",
                                text: "Added !",
                                variant: "success",
                            },
                        });
                    }
                });
            }
        };
        const filterArtist = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchArtists(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const filterMusic = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchMusics(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const filterVideo = (search) => {
            const filters = {
                name: {
                    type: "like",
                    val: search,
                },
            };
            const params = {
                filters,
                pagination: {
                    page: 1,
                },
            };
            isLoading.value = true;
            try {
                fetchVideos(params);
            } catch (err) {
                console.log(err, "there was an error loading models");
            } finally {
                isLoading.value = false;
            }
        };
        const fetchArtists = async (param = {}) => {
            const resource = await artistRepository.index(param);
            artistOption.value = resource.data;
            console.log(resource, "artist");
        };
        const fetchMusics = async (param = {}) => {
            const resource = await musicRepository.index(param);
            musicOption.value = resource.data;
            console.log(resource, "music");
        };
        const fetchVideos = async (param = {}) => {
            const resource = await videoRepository.index(param);
            videoOption.value = resource.data;
            console.log(resource, "video");
        };
        onMounted(() => {
            fetchArtists();
            fetchMusics();
            fetchVideos();
        });
        return {
            onSubmit,
            required,
            coeffientVal,
            options,
            artistOption,
            musicOption,
            videoOption,
            selectedArtist,
            selectedMusic,
            selectedVideo,
            validation,
            musicRankList,
            videoRankList,
            artistRankList,
            addToListHander,
            fetchMusics,
            fetchVideos,
            fetchArtists,
            isLoading,
            form,
            filterArtist,
            filterMusic,
            filterVideo,
            date,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
